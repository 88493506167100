import styled from '@emotion/styled';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import TextField  from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';
import { useDispatch } from 'react-redux';

import { setpoPhone, setpoPhoneAssistant } from '../store';

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const Q3StyledTextField = styled(TextField)(({ theme }) => ({
    '& label': {
    },
    '& label.Mui-focused': {
      color: '#990000',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#E69D25',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
  
      },
      '&:hover fieldset': {
  
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E69D25',
      },
    },
  }));


export default function PhoneNumberInputs( {label, assistantPhone, data}  ) {

  const dispatch = useDispatch();

  const handlePhoneChange = (event, forAdminAssistant = false) => {
    if (forAdminAssistant) {
        dispatch(setpoPhoneAssistant(event.target.value));
        return;
    }
    dispatch(setpoPhone(event.target.value));
};

  return (
    <Stack direction="row" spacing={2}>
        <FormControl variant="standard">
            <Q3StyledTextField  
                        data-tooltip-id={data}
                        label={label}
                        placeholder="(111)-2222-333"
                        onChange={(e) => handlePhoneChange(e, assistantPhone)}
                        name="textmask"
                        id="formatted-text-mask-input"
                        InputProps={{
                            inputComponent: TextMaskCustom,
                        }}
                        />
        </FormControl>
    </Stack>
  );
}