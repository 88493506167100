import { styled } from '@mui/system';
import TextField from "@mui/material/TextField";

export const Q3TextField = styled(TextField)(({ theme }) => ({
  '& label': {
  },
  '& label.Mui-focused': {
    color: '#990000',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#E69D25',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {

    },
    '&:hover fieldset': {

    },
    '&.Mui-focused fieldset': {
      borderColor: '#E69D25',
    },
  },
}));