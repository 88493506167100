import { configureStore, createSlice } from '@reduxjs/toolkit';

const poInfoSlice = createSlice({
  name: 'poInfo',
  initialState: {
    firstName: "John",
    lastName: "Doe",
    title: "Manager",
    department: "Business Office",
    phoneNumber: "(111) 222-333",
    phoneAsistant: null,
    email: "john.doe@questar.org",
    location: {"Central Office": {
      "location": '10 Empire State Boulevard, Castleton, NY 12033',
      "main_line": '(518) 477-8771'
  }}
  },
  reducers: {
    setpoName: (state, action) => {
      state.firstName = action.payload
    },
    setpoLastName: (state, action) => {
      state.lastName = action.payload
    },
    setpoTitle: (state, action) => {
      state.title = action.payload
    },
    setpoDepartment: (state, action) => {
      state.department = action.payload
    },
    setpoPhone: (state, action) => {
      state.phoneNumber = action.payload
    },
    setpoPhoneAssistant: (state, action) => {
      state.phoneAsistant = action.payload
    },
    setpoEmail: (state, action) => {
      state.email = action.payload
    },
    setpoLocation: (state, action) => {
      state.location = action.payload
    },
  },
});

export const { setpoName, setpoLastName, setpoTitle, setpoDepartment, setpoPhone, setpoPhoneAssistant, setpoEmail, setpoLocation } = poInfoSlice.actions;

export const store = configureStore({
  reducer: {
    poInfo: poInfoSlice.reducer,
  },
});
