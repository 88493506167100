import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { useSelector } from 'react-redux';
import "../static/styles/LocationDropdown.css";
import { Button } from "@mui/material";


function LocationDropdown({ showDropdown, setShowDropdown, onSelectLocation, items }) {

    const [isAnimating, setIsAnimating] = useState(false);
    const location = useSelector((state) => state.poInfo.location)

    const handleSelectLocation = (e, location) => {
      e.preventDefault();
        onSelectLocation(location); 
        setShowDropdown(false);
      };

    const subMenuVariants = {
        enter: {
        opacity: 1,
        rotateX: 0,
        transformOrigin: "top center",
        transition: {
            duration: 0.5
        },
        display: "block"
        },
        exit: {
        opacity: 0,
        rotateX: -100,
        transition: {
            ease: "easeOut",
            duration: 0.5
        },
        transitionEnd: {
            display: "none"
        }
        }
    };


    const handleToggleDropdown = (e) => {
        e.preventDefault();
        setIsAnimating(true);
        setShowDropdown(!showDropdown);
    };

  let location_Root = Object.keys(location)[0]
  let locations = Object.keys(items)

  return (
    <div className="dropdown-wrapper">
      <Button
        onClick={(e) => handleToggleDropdown(e)}
        className="trigger-button"
        disabled={isAnimating}
      >
        {location_Root}
      </Button>
      <AnimatePresence>
        {showDropdown && (
          <motion.ul
            initial="exit"
            animate="enter"
            exit="exit"
            variants={subMenuVariants}
            className="dropdown-active"
            onAnimationStart={() => setIsAnimating(true)}
            onAnimationComplete={() => setIsAnimating(false)}
          >
            {locations.map((location, index) => (
              <motion.li
                        onClick={(e) => handleSelectLocation(e, location)}
                        key={index}>
                            {location}
              </motion.li> 
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
}

export default LocationDropdown;
