import SignaturePreview from "./SignaturePreview";



import "../static/styles/EmailBackground.css";

export default function EmailBackgrround() {


    return (
        <div className="email-background">
            <div className="sigpreviewdivider">
            <   SignaturePreview style={{marginTop: '10%'}} />
            </div>
        </div>
    );
}