import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import { items } from '../constants/location';
import Q3Star from '../static/images/Q3Star.png';
import { setpoLastName, setpoLocation, setpoName, setpoTitle } from '../store';
import EmailInput from './EmailInputField';
import LocationDropdown from './LocationDropdown';
import PhoneNumberInputs from './PhoneNumberField';
import { Q3TextField } from './styledCustomComponents/Q3TextField';

import "../static/styles/SignatureForm.css";

function SignatureForm() {

    const [showDropdown, setShowDropdown] = useState(false);

    const dispatch = useDispatch();

    function dropdownonCfunc() {
      setShowDropdown(!showDropdown);
    };

    const handleSelectLocation = (selectedLocation) => {
        let location_str = selectedLocation
        
        let location_obj = {
            [location_str]: items[location_str]
        }

        dispatch(setpoLocation(location_obj))
    };

    
        return (
            <div className="form__container">
                <form>
                <div className='admin_assistant_tooltip__container'>
                        <Tooltip
                        id='tooltip-admin-assistant'
                        content='Admin Assistant phone is optional and if deleted, will be removed from the signature'
                        events={['click']}
                        className='admin_assistant__tooltip'
                        place='top'
                        offset={15}
                        >
                        </Tooltip>
                    </div>
                    <div>
                        <img src={Q3Star} alt="Questar III Logo" className="q3star__logo" />
                        <p className='q3star__text'>Questar III Email Signature Generator</p>
                    </div>
                    <div>
                        <Q3TextField onChange={(e) => dispatch(setpoName(e.target.value))}  id="outlined-controlled" label="First Name" variant="outlined" />
                    </div>

                    <div>
                        <Q3TextField onChange={(e) => dispatch(setpoLastName(e.target.value))}  id="outlined-controlled" label="Last Name" variant="outlined" />
                    </div>
                    
                    <div>
                        <Q3TextField onChange={(e) => dispatch(setpoTitle(e.target.value))}  id="outlined-controlled" label="Title" variant="outlined" />

                    </div>

                    <div>
                    <PhoneNumberInputs  data={"tooltip-admin-assistant"}
                                        label={"Admin Assistant Phone"} 
                                        assistantPhone={true}
                    />
                    </div>

                    
                    <div>
                        <PhoneNumberInputs label={"Direct Line"} />
                    </div>
                    <div>
                        <EmailInput></EmailInput>
                    </div>

                    <div id="location___dropdown">
                        <label>Location</label>
                    <LocationDropdown showDropdown={showDropdown}
                                    setShowDropdown={dropdownonCfunc}
                                    items={items}
                                    onSelectLocation={handleSelectLocation}/>
                    </div>


                </form>
            </div>
    );
}

export default SignatureForm;
