import styled from '@emotion/styled';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import TextField  from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';
import { useDispatch } from 'react-redux';

import { setpoEmail } from '../store';

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="##################################"
      definitions={{
        '#': /[A-Za-z0-9-'.@]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const Q3StyledTextField = styled(TextField)(({ theme }) => ({
    '& label': {
    },
    '& label.Mui-focused': {
      color: '#990000',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#E69D25',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
  
      },
      '&:hover fieldset': {
  
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E69D25',
      },
    },
  }));


export default function EmailInput() {

  const dispatch = useDispatch();

  const handleEmailChange = (event) => {
    const value = event.target.value;
    const newValue = value.includes('@') ? value : `${value}@questar.org`;
    dispatch(setpoEmail(newValue));
};

  return (
    <Stack direction="row" spacing={2}>
        <FormControl variant="standard">
            <Q3StyledTextField  
                        label="Email"
                        placeholder="john.doe@questar.org"
                        onChange={(e) => handleEmailChange(e)}
                        name="textmask"
                        id="formatted-text-mask-input"
                        InputProps={{
                            inputComponent: TextMaskCustom,
                        }}
                        />
        </FormControl>
    </Stack>
  );
}