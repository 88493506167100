import React, { useState } from 'react';
import { browserName, isChrome } from 'react-device-detect';
import { Provider } from 'react-redux';
import SignatureForm from "./Components/SignatureForm";
import "./static/styles/App.css";
import { store } from "./store";
import EmailBackgrround from './Components/EmailBackground';

function App() {
  const [overrideBrowser, setOverrideBrowser] = useState(!isChrome);
  
  const handleOverride = () => {
    setOverrideBrowser(false);
  };

  return (
    <React.StrictMode>
      <Provider store={store}>
      {overrideBrowser && (
            <div className="BrowserWarning">
              <div>
                <img
                                      src={"https://www.questar.org/wp-content/uploads/2023/12/q3logo235by40.png"}
                                      alt="Questar III Logo"
                                      style={{
                                          width: '350px',
                                          display: 'block',
                                          margin: 'auto'
                                      }}
                                  />
                <span id='coverspan'></span>
                <span id='typingspan'>PUTTING STUDENTS FIRST</span>
              </div>
              <p>It looks like you are on an unsupported browser ({browserName}). This application is supported on Chrome 119.0.6045.124 and above.</p>
              <p></p>
              <button onClick={handleOverride}>Continue Anyway</button>
            </div>
          )}
        <div style={{position: 'relative'}} className={`App ${overrideBrowser ? 'App-blur' : ''}`}>
          <SignatureForm />
          <EmailBackgrround />
        </div>
      </Provider>
    </React.StrictMode>
  );
}

export default App;
