export const items = {
    "1070 Instructional Support Center": {
        "location": '1070 Route 9, Castleton, NY 12033',
        "main_line": '(518) 477-8771'
    },
    "Central Office": {
        "location": '10 Empire State Boulevard, Castleton, NY 12033',
        "main_line": '(518) 477-8771'
    },
    "Columbia High School": {
        "location": '962 Luther Road, East Greenbush, NY 12061',
        "main_line": null
    },
    "Donald R. Kline Technical School": {
        "location": '131 Union Turnpike, Hudson, NY 12534',
        "main_line": '(518) 828-4157'
    },
    "George Washington School": {
        "location": '344 Menemsha Lane, Troy, NY 12180',
        "main_line": '(518) 283-5752' 
    },
    "Marilyn A. Noonan School at Durham": {
        "location": '4099 NY-145, Durham, NY 12422',
        "main_line": '(518) 697-5660'
    },
    "New Visions Business, Finance, and Marketing - CGCC": {
        "location": '4400 Route 23, Hudson, NY 12534',
        "main_line": null
    },
    "New Visions EPICH - University at Albany": {
        "location": '350 ETEC, 1220 Washington Avenue, Albany, NY 12226',
        "main_line": null
    },
    "New Visions Medical - Samaritan Hospital": {
        "location": '2215 Burdett Ave, Troy, NY 12180',
        "main_line": null
    },
    "New Visions Pathways in Education - University at Albany": {
        "location": '1400 Washington Avenue, Albany, NY 12222',
        "main_line": null
    },
    "New Visions Scientific Research & World Health - University at Albany": {
        "location": '1 University Place, Rensselaer, NY 12144',
        "main_line": null
    },
    "New Visions STEM - RPI": {
        "location": '110 8th Street, Troy NY 12180',
        "main_line": null
    },
    "New Visions Visual and Performing Arts - The Arts Center of the Capital Region": {
        "location": '265 River Street, Troy, NY 12180',
        "main_line": null
    },
    "Paul Puccio School at Maple Hill": {
        "location": '1477 South Schodack Road, Castleton, NY 12033',
        "main_line": '(518) 479-6999'
    },
    "Pre-K - Berlin CSD": {
        "location": '17400 Route 22, Cherry Plain, NY 12040',
        "main_line": null
    },	  
    "Pre-K - Cairo-Durham CSD": {
        "location": '424 Main Street, Cairo, NY 12413',
        "main_line": null
    },
    "Pre-K - Catskill Elementary School": {
        "location": '770 Embought Road, Catskill, NY 12414',
        "main_line": null
    },
    "Pre-K - Donald P. Sutherland Elementary School": {
        "location": '4 John Street, Nassau, NY 12123',
        "main_line": '111-222-333'
    },  
    "Pre-K - Genet Elementary School": {
        "location": '29 Englewood Avenue, East Greenbush, NY 12061',
        "main_line": null
    },  
    "Pre-K - Green Meadow Elementary School": {
        "location": '234 Schuurman Road, Castleton-On-Hudson, NY 12033',
        "main_line": null
    },
    "Pre-K - Montgomery C. Smith Elementary School": {
        "location": '215 Harry Howard Avenue, Hudson, NY 12534',
        "main_line": null
    },
    "Pre-K - Ichabod Crane CSD": {
        "location": '2910 Route 9, Valatie, NY 12184',
        "main_line": null
    },
    "Pre-K - Red Mill Elementary School": {
        "location": '225 McCullough Place, Rensselaer, NY 12144',
        "main_line": null
    },
    "Questar III & HVCC STEM High School ": {
        "location": 'Lang Building, 80 Vandenburgh Avenue Troy, NY 12180',
        "main_line": '(518) 629-7600'
    },
    "Rensselaer Academy": {
        "location": '25 Van Rensselaer Drive, Rensselaer, NY 12144',
        "main_line": '(518) 396-3520'
    },
    "Rensselaer Junior-Senior High School": {
        "location": '25 Van Rensselaer Drive, Rensselaer, NY 12144',
        "main_line": '(518) 396-3520'
    },
    "Robert H. Gibson Technical School": {
        "location": '35 Colleen Road, Troy, NY 12180',
        "main_line": '(518) 273-2264'
    },
    "Robin Sobol Transition Academy - HVCC": {
        "location": 'Fitzgibbons Health Technologies Center, 80 Vandenburgh Avenue, Troy, NY 12180',
        "main_line": null
    },
    "Sackett Educational Center": {
        "location": '200 Schuurman Road, Castleton, NY 12033',
        "main_line": '(518) 479-3520'
    },
  };
  