import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Oval } from 'react-loader-spinner'
import { useSelector } from 'react-redux';

import "../static/styles/SignaturePreview.css";

function SignaturePreview() {
    const poInfo = useSelector((state) => state.poInfo);
    const [copied, setCopied] = useState(false);
    const [dividerHeight, setdividerHeight] = useState(0);
    const [currentLocBroken, setcurrentLocBroken] = useState(null);
    const [currentLocPreview, setcurrentLocPreview] = useState(null);
    const [curretLocBreakPreview, setcurrentLocBreakPreview] = useState(null);
    const [previewLoading, setpreviewLoading] = useState(true);
    
    const poName = `${poInfo.firstName} ${poInfo.lastName}`;
    const poEmail = `${poInfo.email}`;
    const potitle = poInfo.title;
    const pophone = poInfo.phoneNumber;
    const pophoneAsisstant = poInfo.phoneAsistant;
    const polocation = poInfo.location;
    
    const previewRef = useRef(null);
    const tbodyRef = useRef(null);
    const logosloganRef = useRef(null);
    const dividerRef = useRef(null);
    const poInfoRef = useRef(null);
    const locationRef = useRef(null);
    const locationRef2 = useRef(null);

    let location_Root = Object.keys(polocation);
    let main_line = polocation[location_Root].main_line;

    const [currentLoc, setcurrentLoc] = useState(polocation[location_Root].location);


    const tickVariants = {
        hidden: { scale: 0 },
        visible: { scale: 2 },
    };

    const tableStyle = {
        fontWeight: '300',
        borderCollapse: 'collapse',
        tableLayout: 'auto',
        caretColor: 'transparent',
        fontSize: '12px',
        fontFamily: 'Calibri',
        overflow: 'visible'
    };

    const rightCellStyle = {
        padding: '5px',
        display: 'table-cell',
        position: 'relative',
        verticalAlign: 'middle',
        margin: '5px',
    };

    const leftCellStyle = {
        width: `${location_Root[0].length * 7.2}px`,
        textAlign: 'left',
        verticalAlign: 'top',
        padding: '0', // Reduced padding
        color: 'black',
        lineHeight: '14px'
    };

    const dividerStyle = {
        margin: '10px',
        width: '5px',
    };

    
    const handleCopy = async () => {
        if (previewRef.current) {
            let html = previewRef.current.outerHTML;
    
            // Height adjustment for the divider
            const heightRegex = /(<img\s+id="divider___bar"\s+alt=""\s+src=".*?"\s+style="width:\s*1px;\s*height:\s*)(\d+)(px;\s*border-radius:\s*30px;">)/;
            const heightMatch = html.match(heightRegex);
    
            if (heightMatch && heightMatch.length === 4) {
                const adjustedHeight = parseInt(heightMatch[2]) + 18; // Adjust this value as needed
                html = html.replace(heightRegex, `$1${adjustedHeight}$3`);
            }
    
            // Adjust width for older Outlook versions using [if mso]
        const msoWidthRegex = /(<td\s+id="leftcell"\s+style=".*?width:\s*)(\d+)(%\s*;.*?<\/td>)/;
        const msoWidthMatch = html.match(msoWidthRegex);

        if (msoWidthMatch && msoWidthMatch.length === 4) {
            const adjustedMsoWidth = parseInt(msoWidthMatch[2]) - 5; // Adjust as needed
            html = html.replace(msoWidthRegex, `$1${adjustedMsoWidth}$3`);
        }


        let revisedHtml = html;

        // Adjust width using regex for "if !mso" version
        const widthRegex = /(<td\s+id="leftcell"\s+style=".*?width:\s*)(\d+)(%\s*;.*?<\/td>)/;
        const widthMatch = html.match(widthRegex);

        if (widthMatch && widthMatch.length === 4) {
            const adjustedWidth = parseInt(widthMatch[2]) - 5; // Adjust this value as needed
            revisedHtml = revisedHtml.replace(widthRegex, `$1${adjustedWidth}$3`);
        }

        // Find matches for Main Line and replace leading spaces with non-breaking spaces (nbsp)
        const mainlineregex = /!mso.*(\s)(-)\s(Main Line)/;
        revisedHtml = revisedHtml.replace(mainlineregex, `$1&nbsp;$2`)

        // Find matches for Direct Line and replace leading spaces with non-breaking spaces (nbsp)
        const directLineRegex = /(\(\d{3}\) \d{3}-\d{4} -&nbsp;Direct Line)(\s+)/g;
        revisedHtml = revisedHtml.replace(directLineRegex, (match, line, space) => line.replace(/ /g, '&nbsp;') + space);
        
        const dividerRegex = /(<td\s+id=['"]divider['"]\s+[^>]*?>[\s\S]*?<\/td>)/i;
        const dividerMatch = html.match(dividerRegex);

        if (dividerMatch && dividerMatch.length === 2) {
            // Replace <td id='divider' ...> with <td style="background-color: #2596be; width: 0.1%;">
            html = html.replace(dividerRegex, '<td style="background-color: #f86464; width: 0.2%;"></td>');
        }
        // Create "if mso" version
        const msoHtml = `<!--[if mso]>${html}<![endif]-->`;

        // Create "if !mso" version
        const nonMsoHtml = `<!--[if !mso]><![if gte mso 9]><div><![endif]-->${revisedHtml}<!--[if gte mso 9]></div><![endif]><![endif]-->`;

        // Combine both versions for clipboard copy
        const fullHtml = `<div>${msoHtml}${nonMsoHtml}</div>`;

    
            try {
                await navigator.clipboard.write([
                    new ClipboardItem({
                        'text/html': new Blob([fullHtml], { type: 'text/html' }),
                    }),
                ]);
                setCopied(true);
                setTimeout(() => setCopied(false), 30000);
            } catch (err) {
                console.error('Failed to copy HTML: ', err);
            }
        }
    };

    useEffect(() => {
        const adjustCellHeight = () => {
            if (dividerRef.current) {
                const leftCellHeight = poInfoRef.current.clientHeight;
                const rightCellHeight = logosloganRef.current.clientHeight;
                
                let biggerCellheight = Math.max(leftCellHeight, rightCellHeight);
                let heightOffset = 3
    
                if (pophoneAsisstant === "") {
                    heightOffset = 4;
                };
                setdividerHeight(biggerCellheight - heightOffset);
            };
        }

        const adjustCellWidth = () => {
            let widthOffset = 5
    
            if (locationRef.current) {
                const locationWidth = Math.max(locationRef.current.clientWidth, locationRef2.current.clientWidth) + widthOffset;
                const newLeftCellStyle = {
                    ...leftCellStyle,
                    width: `${locationWidth}px`,
                };
    
                if (poInfoRef.current) {
                    poInfoRef.current.style.width = newLeftCellStyle.width;
                }
            }
        }

        setTimeout(() => {
            setpreviewLoading(false);
        }, 1000);
        const intervalId = setInterval(() => {
            adjustCellHeight();
            adjustCellWidth();
        }, 10);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [polocation, pophone, pophoneAsisstant]);

    useEffect(() => {
        let current_loc = polocation[location_Root].location;
        let current_loc_preview = location_Root[0];
        setcurrentLoc(current_loc);
        setcurrentLocPreview(location_Root[0]);

        setcurrentLocBroken(null);
        setcurrentLocBreakPreview(null);
        
        if (current_loc_preview === "New Visions Business, Finance, and Marketing - CGCC") {
            setcurrentLocPreview("New Visions Business");
            setcurrentLocBreakPreview("Finance, and Marketing - CGCC");
        } else if (current_loc_preview === "New Visions EPICH - University at Albany") {
            setcurrentLocPreview("New Visions EPICH");
            setcurrentLocBreakPreview("University at Albany")
        } else if (current_loc_preview === "New Visions Medical - Samaritan Hospital") {
            setcurrentLocPreview("New Visions Medical");
            setcurrentLocBreakPreview("Samaritan Hospital")
        } else if (current_loc_preview === "New Visions Pathways in Education - University at Albany") {
            setcurrentLocPreview("New Visions Pathways in Education");
            setcurrentLocBreakPreview("University at Albany")
        } else if (current_loc_preview === "New Visions Scientific Research & World Health - University at Albany") {
            setcurrentLocPreview("New Visions Scientific Research & World Health");
            setcurrentLocBreakPreview("University at Albany")
        } else if (current_loc_preview === "New Visions Visual and Performing Arts - The Arts Center of the Capital Region") {
            setcurrentLocPreview("New Visions Visual and Performing Arts");
            setcurrentLocBreakPreview("The Arts Center of the Capital Region")
        }

        if (current_loc === "Fitzgibbons Health Technologies Center, 80 Vandenburgh Avenue, Troy, NY 12180") {
            setcurrentLoc("Fitzgibbons Health Technologies Center")
            setcurrentLocBroken("80 Vandenburgh Avenue, Troy, NY 12180")
            console.log('triggered failsafe')
        } else if (current_loc === "Lang Building, 80 Vandenburgh Avenue Troy, NY 12180") {
            setcurrentLoc("Lang Building")
            setcurrentLocBroken("80 Vandenburgh Avenue Troy, NY 12180")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [polocation])



    
    return (
        <div className="preview___wrapper">
            <Oval
            visible={previewLoading}
            wrapperClass="loading_oval"
            width="160"
            height="160"
            color="#E69D25"
            />
            <div style={{ visibility: previewLoading ? 'hidden' : 'visible' }} className="preview___container">                

            <table border="0" cellPadding="0" cellSpacing="0" style={tableStyle} ref={previewRef}>
                    <tbody ref={tbodyRef}>
                        <tr>
                            <td id="leftcell" ref={poInfoRef} style={leftCellStyle}>
                                {/* Contact details */}
                                <h2 style={{ color: 'black', fontSize: '14.6667px', fontWeight: 'bold', margin: '0', padding: '0' }}>{poName}</h2>
                                <p style={{ margin: '0', fontSize: '12px', color: 'black', fontWeight: 'lighter'}}>{potitle}</p>
                                <p ref={locationRef} style={{ margin: '0', marginBottom: '0', color: 'black', fontSize: '12px', fontWeight: 'lighter'}}>Questar III BOCES | {currentLocPreview}</p>
                                <p ref={locationRef} style={{ margin: '0', marginBottom: '0', color: 'black', fontSize: '12px', fontWeight: 'lighter'}}>{curretLocBreakPreview}</p>
                                {main_line && <p style={{ margin: '0', marginBottom: '0', color: 'black', fontSize: '12px', fontWeight: 'lighter'}}>{main_line}<span style={{color: 'rgba(0, 0, 0, 1)', fontSize: '12px', fontWeight: 'lighter',fontFamily: 'Arial'}}> - </span> Main Line</p>}
                                <p style={{ margin: "0px", whiteSpace: "nowrap", fontSize: "12px", fontWeight: "lighter" }}>
                                        
                                {pophone && (
                                <>
                                    {pophone}<span style={{color: 'rgba(0, 0, 0, 1)', fontSize: '12px', fontWeight: 'lighter',fontFamily: 'Arial'}}> - </span>Direct Line
                                    <br></br>
                                </>)}
                                    {pophoneAsisstant ? (
                                        <>
                                            {pophoneAsisstant}<span style={{color: 'rgba(0, 0, 0, 1)', fontSize: '12px', fontWeight: 'lighter',fontFamily: 'Arial'}}> - </span>Administrative Assistant
                                            <br></br>
                                            <a className="email__link" href={`mailto:${poEmail}`}>{poEmail}</a><br />
                                            <a className="website__link" href="https://www.questar.org">www.questar.org</a>
                                        </>
                                    ) : (
                                        <>
                                            <a className="email__link" href={`mailto:${poEmail}`}>{poEmail}</a><br />
                                            <a className="webiste__link" href="https://www.questar.org">www.questar.org</a>
                                        </>
                                    )}
                                </p>
                                <p ref={locationRef2} style={{ margin: '0', marginBottom: '0', color: 'black', fontSize: '12px', fontWeight: 'lighter', whiteSpace: 'nowrap'}}>{currentLoc}</p>
                                <p style={{ margin: '0', marginBottom: '0', color: 'black', fontSize: '12px', fontWeight: 'lighter', whiteSpace: 'nowrap'}}>{currentLocBroken}</p>
                            </td>
                            <td id='divider' style={dividerStyle}>
                                <img id="divider___bar" ref={dividerRef} alt="" style={{width: '1px', height: `${dividerHeight}px`, borderRadius: '30px'}} src={"https://www.questar.org/wp-content/uploads/2023/12/q3bar-2.png"}></img>
                            </td>
                            <td id='rightcell' ref={logosloganRef} style={rightCellStyle}>
                                {/* Logo and slogan */}
                                <img
                                    id="logo"
                                    src="https://www.questar.org/wp-content/uploads/2023/12/q3logo235by40.png"
                                    alt="Questar III Logo"
                                    style={{
                                        margin: '5px 3px 0 0px',
                                    }}
                                />
                                <p id="slogan" style={{
                                    color: '#999',
                                    fontSize: '12px',
                                    fontStyle: 'italic',
                                    overflowWrap: 'break-word',
                                    lineHeight: '12px',
                                }}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Changing lives, realizing dreams<br />and doing together what can't be done alone.
                                </p>
                            </td>
                            
                        </tr>                                                                                                                                          
                    </tbody>
                </table>
                <button id="copybutton" onClick={handleCopy}>
                    {copied ? "Copied successfully" : "Copy to Clipboard"}
                    <motion.span
                        variants={tickVariants}
                        initial="hidden"
                        animate={copied ? "visible" : "hidden"}
                        className="checkmark"
                        >
                        ✓
                    </motion.span>
                </button>
                
            </div>
        </div>
    );
}
  
export default SignaturePreview;
  